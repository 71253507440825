
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import Button from '@/src/components/Button';
import AnchorLink from '@/src/components/AnchorLink';
import { buildContentfulImageURL } from '@utils/image';
import useTrackEventOnShow from '../../../../hooks/useTrackEventOnShow';
import Image from '../../../../components/Image';
import { trackClickEvent } from '../../../../utils/analytics';
import { analyticsTags } from '../../../../utils';
import s from './Promo.module.scss';
const propTypes = {
    displayTitle: PropTypes.string,
    intro: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    pageName: PropTypes.string,
    tags: PropTypes.array,
    contentTypeId: PropTypes.string,
    contentfulId: PropTypes.string,
    promoLogo: PropTypes.string,
    promoType: PropTypes.string.isRequired
};
const componentName = 'Promo';
const Promo = ({ displayTitle = '', intro = '', image = '', label = '', url = '', pageName = '', contentTypeId = '', contentfulId = '', tags = [], promoLogo = '', promoType, }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    const [ref] = useTrackEventOnShow({
        threshold: 0.3,
        eventName: 'Promo Content Displayed',
        eventOptions: {
            cta_text: label,
            content_name: displayTitle,
            content_type: contentTypeId,
            content_location: promoType,
            content_index: 1,
            content_id: contentfulId,
            content_tags: analyticsTags(tags),
            location: pageName,
            nonInteraction: 1
        }
    });
    const promoLogoSet = [
        {
            src: promoLogo
        },
    ];
    return (<div className={s.promo} data-testid="promoRoot" ref={ref}>
      <div className={s.image} ref={componentRef}>
        <Image imageSet={[
            {
                src: `${buildContentfulImageURL(image, {
                    width: 375,
                    height: 550,
                    fit: 'fill',
                    format: 'webp'
                })} 1x, ${buildContentfulImageURL(image, {
                    width: 750,
                    height: 1100,
                    fit: 'fill',
                    format: 'webp'
                })} 2x, ${buildContentfulImageURL(image, {
                    width: 1125,
                    height: 1650,
                    fit: 'fill',
                    format: 'webp'
                })} 3x`,
                media: '(max-width: 480px)',
                type: 'image/webp'
            },
            {
                src: `${buildContentfulImageURL(image, {
                    width: 375,
                    height: 550,
                    fit: 'fill',
                    format: 'jpg'
                })} 1x, ${buildContentfulImageURL(image, {
                    width: 750,
                    height: 1100,
                    fit: 'fill',
                    format: 'jpg'
                })} 2x, ${buildContentfulImageURL(image, {
                    width: 1125,
                    height: 1650,
                    fit: 'fill',
                    format: 'jpg'
                })} 3x`,
                media: '(max-width: 480px)',
                type: 'image/jpeg'
            },
            {
                src: `${buildContentfulImageURL(image, {
                    width: 750,
                    height: 1100,
                    fit: 'fill',
                    format: 'webp'
                })} 1x, ${buildContentfulImageURL(image, {
                    width: 1550,
                    height: 2200,
                    fit: 'fill',
                    format: 'webp'
                })} 2x`,
                media: '(max-width: 1024px)',
                type: 'image/webp'
            },
            {
                src: `${buildContentfulImageURL(image, {
                    width: 750,
                    height: 1100,
                    fit: 'fill',
                    format: 'jpg'
                })} 1x, ${buildContentfulImageURL(image, {
                    width: 1550,
                    height: 2200,
                    fit: 'fill',
                    format: 'jpg'
                })} 2x`,
                media: '(max-width: 1024px)',
                type: 'image/jpeg'
            },
            {
                src: `${buildContentfulImageURL(image, {
                    width: 1440
                })} 1x, ${buildContentfulImageURL(image, { width: 2880, format: 'webp' })} 2x`,
                media: '(max-width: 1440px)',
                type: 'image/webp'
            },
            {
                src: `${buildContentfulImageURL(image, {
                    width: 1440,
                    format: 'jpg'
                })} 1x, ${buildContentfulImageURL(image, { width: 2880, format: 'jpg' })} 2x`,
                media: '(max-width: 1440px)',
                type: 'image/jpeg'
            },
            {
                src: buildContentfulImageURL(image, { width: 2560, format: 'webp' }),
                media: '(max-width: 2560px)',
                type: 'image/webp'
            },
            {
                src: buildContentfulImageURL(image, { width: 2560, format: 'jpg' }),
                media: '(max-width: 2560px)',
                type: 'image/jpeg'
            },
            {
                src: buildContentfulImageURL(image, { format: 'webp' }),
                type: 'image/webp'
            },
            {
                src: buildContentfulImageURL(image, { width: 2560, format: 'jpg' })
            },
        ]} cover lazy/>
      </div>
      <div className={s.contentWrapper}>
        <Image className={s.logo} imageSet={promoLogoSet}/>
        <div className={s.displayTitle}>{displayTitle}</div>
        <div className={s.intro}>{intro}</div>
        <div className={s.buttonWrapper}>
          <AnchorLink href={url}>
            <Button type="secondary" label={label} tabIndex="-1" data-testid="promo-cta" onClick={() => {
            trackClickEvent({
                destination_url: url,
                label,
                content_name: displayTitle,
                content_type: contentTypeId,
                content_id: contentfulId,
                content_tags: analyticsTags(tags),
                location: componentName,
                vertical_index: verticalIndex,
                horizontal_index: 0
            });
        }}/>
          </AnchorLink>
        </div>
      </div>
    </div>);
};
Promo.propTypes = propTypes;
export default Promo;

    async function __Next_Translate__getStaticProps__194cc797bff__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/Promo/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc797bff__ as getStaticProps }
  
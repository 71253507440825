
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import s from './MoreCollections.module.scss';
import CollectionDiscoveryItem from './CollectionDiscoveryItem';
import CollectionDiscoveryLink from './CollectionDiscoveryLink';
const propTypes = {
    data: PropTypes.array,
    headline: PropTypes.string,
    pageName: PropTypes.string,
    theme: PropTypes.string
};
const MoreCollections = ({ data = [], headline = 'Explore more collections', pageName = '', theme = 'Dark', }) => {
    if (data.length <= 0)
        return null;
    const showLarge = data.length === 1 || data.length === 3 || data.length === 5;
    const showSmall = data.length > 1;
    const smallImageData = !showLarge ? data : data.slice(1);
    return (<aside className={s.root} data-testid="moreCollectionsRoot">
      <div className={s.innerWrapper}>
        <h2 className={[s.mainTitle, theme === 'Light' ? s.mainTitleDark : ''].join(' ')} data-testid="headline">
          {headline}
        </h2>
      </div>

      {showLarge && (<CollectionDiscoveryItem isLarge baseURL={data[0].mainImage?.image?.file?.url} displayTitle={data[0].displayTitle} contentfulId={data[0].contentfulId}>
          <CollectionDiscoveryLink item={data[0]} index={0} pageName={pageName}/>
        </CollectionDiscoveryItem>)}

      {showSmall && (<div className={s.secondaryBackground}>
          {smallImageData.map((item, index) => (<CollectionDiscoveryItem key={item?.contentfulId} baseURL={item?.mainImage?.image?.file?.url} displayTitle={item?.displayTitle}>
              <CollectionDiscoveryLink item={item} index={index + 1} pageName={pageName}/>
            </CollectionDiscoveryItem>))}
        </div>)}
    </aside>);
};
MoreCollections.propTypes = propTypes;
export default MoreCollections;

    async function __Next_Translate__getStaticProps__194cc79785e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/MoreCollections/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc79785e__ as getStaticProps }
  

    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from '../../../../../components/AnchorLink';
import s from './CollectionDiscoveryLink.module.scss';
import { trackEvent } from '../../../../../utils/analytics';
const propTypes = {
    item: PropTypes.shape({
        contentfulId: PropTypes.string,
        contentTypeId: PropTypes.string,
        externalId: PropTypes.string,
        displayTitle: PropTypes.string,
        displayTitleEyebrow: PropTypes.string
    }),
    index: PropTypes.number,
    pageName: PropTypes.string
};
const CollectionDiscoveryLink = ({ item = {}, index = 0, pageName = '' }) => (<AnchorLink href={`/collections/${item.externalId}`} title={item.displayTitle} className={s.innerWrapper} data-testid="collection-discovery-link" onClick={() => {
        trackEvent('Content Clicked', {
            content_name: item.displayTitle,
            content_type: item.contentTypeId,
            content_id: item.contentfulId,
            carousel_index: index,
            carousel_name: 'More Collections',
            location: pageName
        });
    }}>
    <p className={s.eyebrow}>{item.displayTitleEyebrow}</p>
    <h3 className={s.title}>{item.displayTitle}</h3>
  </AnchorLink>);
CollectionDiscoveryLink.propTypes = propTypes;
export default CollectionDiscoveryLink;

    async function __Next_Translate__getStaticProps__194cc7a47d3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/MoreCollections/CollectionDiscoveryLink/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc7a47d3__ as getStaticProps }
  